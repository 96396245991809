<template>
    <LayoutNext>
        <template #page-title>
            Manage Asset Label
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><router-link to="/asset-labels">Asset Labels</router-link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    Manage Asset Label
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <MDBCard class="shadow-0 card-container">
            <MDBCardHeader class="p-2">
                <h6 class="fw-bold">Assigned Users</h6>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column p-0">
                <div class="overflow-auto mt-3" id="table-container">
                    <MDBTable hover class="align-middle">
                        <thead class="table-light">
                            <tr>
                                <th class="sticky-top" v-for="(item, i) in tableHeaders" :key="i" scope="col">
                                    {{ item.header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="isLoading">
                                <tr>
                                    <td colspan="2" class="text-center">
                                        <div class="image-container d-flex justify-content-center align-items-center">
                                            <div class="loader"></div>
                                            <img src="@/assets/cs-loader.png" alt="Loading image">
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr v-if="assignedUsersData.assignedUsers.length === 0">
                                    <td colspan="2">There are no assigned users.</td>
                                </tr>
                                <tr v-else v-for="(item, i) in assignedUsersData.assignedUsers" :key="i">
                                    <td>{{ item.firstName }} {{ item.lastName }}</td>
                                    <td>{{ item.email }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </MDBTable>
                </div>
                <MDBToast v-model="toastError" id="basic-danger-example" :delay="2000" autohide position="top-right"
                    appendToBody stacking width="350px" color="danger" text="white">
                    <template #title>
                        {{ toastMessage }}
                    </template>
                </MDBToast>
            </MDBCardBody>
        </MDBCard>
    </LayoutNext>
</template>

<script setup>
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBTable, MDBToast, MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-vue-ui-kit";
import { useRoute } from "vue-router";
import { computed, onBeforeMount, ref } from "vue";
import axios from "axios";
import { useTitle } from "@vueuse/core";

useTitle("Manage Asset Label | Creator Shield");

const route = useRoute();
const assetLabelId = route.params.id;
const isLoading = ref(false);

const tableHeaders = computed(() => [
    {
        header: "Name",
    },
    {
        header: "Email",
    },
]);

const assignedUsersData = ref({
    assignedUsers: [],
    id: "",
    name: "",
    partnerPercentage: "",
});

const toastError = ref(false);
const toastMessage = ref("");

onBeforeMount(async () => {
    isLoading.value = true;
    try {
        const response = await axios.get("api/assetlabels/" + assetLabelId);
        assignedUsersData.value = response.data;

        if (!response.data.assignedUsers || response.data.assignedUsers.length === 0) {
            assignedUsersData.value.assignedUsers = [];
        }

        isLoading.value = false;
    } catch (error) {
        isLoading.value = false;
        toastError.value = true;
        toastMessage.value = "An error occurred during the request: " + error.message;
    }
});
</script>

<style scoped>
th {
    text-transform: uppercase;
}

tr {
    cursor: default;
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}

#table-container {
    flex: 1 1 auto;
    height: 58vh;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
